import { incrementingArray, shuffleArray } from "../util";
const schulteTable = document.querySelector("body.schulte-table");
if (schulteTable !== null) {
  const footer = document.querySelector("#js-footer");
  const clock = document.createElement("div");
  clock.id = "js-clock";
  clock.classList.add("clock");
  clock.dataset.timeOnLoad = new Date().getTime();
  footer.appendChild(clock);

  setInterval(() => {
    const distance = new Date().getTime() - clock.dataset.timeOnLoad;
    const seconds = String(Math.floor(distance / 1000)).padStart(2, "0");
    const fractionalPart = Math.round((distance / 100) % 9);
    clock.innerText = `${seconds}:${fractionalPart}`;
  }, 100);

  // fill body

  const container = document.querySelector("#js-schulte-container");
  for (const cellContent of shuffleArray(incrementingArray(25))) {
    const cell = document.createElement("div");
    cell.appendChild(
      document.createTextNode(
        // String.fromCharCode(cellContent+64) // Capital A-Y
        cellContent // Number 1-25
      )
    );
    container.append(cell);
  }
}

import { zeroBasedIncrementingArray, shuffleArray } from "../util";

const Exercises = class {
  constructor(exerciseWrapper) {
    this.submit = document.querySelector("#js-submit");
    this.assignmentWrapper = document.querySelector("#js-assignment-wrapper");
    this.solutionWrapper = document.querySelector("#js-solution-wrapper");
    this.solutionOutline = document.querySelector("#js-solution-outline");
    this.assignment = document.querySelector("#js-assignment");
    this.solution = document.querySelector("#js-solution");
    this.spinnerElem = document.querySelector("#js-spinner");
    this.exerciseCounter = document.querySelector("#js-exercise-counter");

    this.exerciseWrapper = exerciseWrapper;
    this.currentExercise = 0;
    this.autoSubmitting = false;

    this.sizesAndScores = {
      1: { size: 3 },
      2: { size: 4 },
      3: { size: 5 },
      4: { size: 6 },

      5: { size: 4 },
      6: { size: 5 },
      7: { size: 6 },
      8: { size: 7 },

      9: { size: 5 },
      10: { size: 6 },
      11: { size: 7 },
      12: { size: 8 },

      13: { size: 6 },
      14: { size: 7 },
      15: { size: 8 },
      16: { size: 9 },

      17: { size: 7 },
      18: { size: 8 },
      19: { size: 9 },
      20: { size: 10 },

      21: { size: 8 },
      22: { size: 9 },
      23: { size: 10 },
      24: { size: 11 },
    };

    this.submit.addEventListener("click", () => this.exercise());
    const solution = document.querySelector("#js-solution");

    solution.addEventListener("keyup", function (event) {
      if (event.key === "Enter") {
        window.exercises.submit.click();
      }
      if (solution.value.length == window.exercises.currentAssignment().size) {
        window.exercises.autoSubmitting = true;
        window.exercises.handleAutoSubmit();
      } else {
        window.exercises.autoSubmitting = false;
      }
    });
  }

  async handleAutoSubmit() {
    this.spinnerElem.classList.remove("mask");
    const spinners = [
      "◼︎◼︎◼︎◼︎◼︎",
      "◻︎◼︎◼︎◼︎◼︎",
      "◻︎◻︎◼︎◼︎◼︎",
      "◻︎◻︎◻︎◼︎◼︎",
      "◻︎◻︎◻︎◻︎◼︎",
      "◻︎◻︎◻︎◻︎◻︎",
    ];

    for (const spinner of spinners) {
      if (!this.autoSubmitting) {
        break;
      }

      this.spinnerElem.innerText = spinner;
      await this.sleep(200);
    }

    if (this.autoSubmitting) {
      this.submit.click();
    } else {
      this.spinnerElem.classList.add("mask");
    }
  }

  prepare() {
    const startButton = document.querySelector("#js-start-exercise");
    const assignmentLabel = document.querySelector("#js-assignment-label");
    for (const element of [
      this.assignmentWrapper,
      this.solutionWrapper,
      assignmentLabel,
    ]) {
      element.classList.add("mask");
    }
    startButton.addEventListener("click", () => {
      startButton.classList.add("mask");
      assignmentLabel.classList.remove("mask");

      this.exercise();
    });
  }

  currentAssignment = () => this.sizesAndScores[this.currentExercise];
  sleep = (ms) => new Promise((r) => setTimeout(r, ms));

  exercise() {
    this.submit.disabled = true;
    if (this.solution.value !== "" && this.currentExercise > 0) {
      this.currentAssignment().solution = this.solution.value;
    }
    this.spinnerElem.classList.add("mask");

    this.currentExercise++;
    if (this.currentExercise > Object.keys(this.sizesAndScores).length) {
      this.showScores();
      return;
    }

    this.exerciseCounter.innerHTML = `${this.currentExercise} / ${
      Object.keys(this.sizesAndScores).length
    }`;

    const currentTask = this.sizesAndScores[this.currentExercise];
    currentTask.assignment = this.#exerciseGenerator(currentTask.size);

    this.assignment.innerText = "";
    this.assignmentWrapper.classList.remove("mask");

    this.solution.innerText = "";
    this.solution.maxLength = currentTask.assignment.length;
    this.solution.size = currentTask.assignment.length;
    this.solution.value = "";

    this.solutionOutline.innerText = "";

    this.solutionWrapper.classList.add("mask");
    currentTask.assignment.forEach((number) => {
      const assignmentChar = document.createElement("div");
      assignmentChar.innerText = number;
      this.assignment.appendChild(assignmentChar);

      const solutionChar = document.createElement("div");
      solutionChar.innerText = " ";
      this.solutionOutline.appendChild(solutionChar);
    });

    setTimeout(() => {
      this.assignmentWrapper.classList.toggle("mask");
      this.solutionWrapper.classList.toggle("mask");
      this.solution.value = "";
      this.solution.focus();
      this.submit.disabled = false;
    }, 4000);
  }

  showScores() {
    const scores = {};
    for (const exercise in this.sizesAndScores) {
      const exerciseSize = this.sizesAndScores[exercise].size;
      if (scores[exerciseSize]) {
        const score = scores[exerciseSize];
        const { correct, wrong } = this.correctOrWrong(
          this.sizesAndScores[exercise]
        );
        score.correct += correct;
        score.wrong += wrong;
      } else {
        scores[exerciseSize] = this.correctOrWrong(
          this.sizesAndScores[exercise]
        );
      }
    }
    console.log(scores);
    const scoreTable = document.querySelector("#js-score-table");

    for (const key in scores) {
      const score = scores[key];
      const percentage = (score.correct * 100) / (score.correct + score.wrong);
      let bar = document.createElement("div");
      bar.classList.add("bar");
      bar.style.height = `${percentage}%`;

      let count = document.createElement("div");
      count.innerHTML = key;
      count.classList.add("count");

      let countPercentage = document.createElement("div");
      countPercentage.innerHTML = `${percentage.toFixed(2)}%`;
      countPercentage.classList.add("percentage");

      scoreTable.appendChild(bar);
      scoreTable.appendChild(count);
      scoreTable.appendChild(countPercentage);
    }
  }

  correctOrWrong(exercise) {
    if (exercise.assignment.join("") === exercise.solution) {
      return { correct: 1, wrong: 0 };
    }
    return { wrong: 1, correct: 0 };
  }

  #exerciseGenerator(size) {
    const cellContents = new Uint8Array(size);
    let randomness = zeroBasedIncrementingArray(10);
    return cellContents.map((_elem, index) => {
      if (index % (randomness.length - 1) === 0) {
        randomness = shuffleArray(randomness);
      }
      return randomness[index % (randomness.length - 1)];
    });
  }
};

const workingMemory = document.querySelector("body.working-memory");
if (workingMemory !== null) {
  const exerciseWrapper = document.querySelector("#js-working-memory");
  const exercises = new Exercises(exerciseWrapper);
  window.exercises = exercises;
  exercises.prepare();
}

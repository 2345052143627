export const zeroBasedIncrementingArray = (size) => {
  result = new Uint8Array(size);
  result.forEach((_, index, array) => {
    array[index] = index;
  });
  return result;
};

export const incrementingArray = (size) => {
  return zeroBasedIncrementingArray(size).map((elem) => {
    return elem + 1;
  });
};

export const shuffleArray = (incoming) => {
  const result = Array.from(incoming);

  result.forEach((elem, index, array) => {
    const shuffleFrom = Math.floor(Math.random() * array.length);
    const temp = elem;

    array[index] = array[shuffleFrom];
    array[shuffleFrom] = temp;
  });

  return result;
};
